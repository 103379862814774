import { useState } from "react";

import { ConfirmCancelPromptModal } from "components/modal/PromptModal";
import { useErrorModal } from "components/modal/useErrorModal";
import { useProjectByIdQuery } from "components/fetch/useProjectsQuery";
import { useProject } from "components/project/ProjectProvider";
import { useMyOrganisationsProjects } from "components/project/my-organisation/MyOrganisationsProjectsContext";

export const DeleteProjectButton = ({
  projectId,
  projectTitle,
  forceNavigate,
}: {
  projectId: number;
  projectTitle: string;
  forceNavigate: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => {
  const [showPrompt, setShowPrompt] = useState(false);
  const { setErrorModal } = useErrorModal();
  const { data: project, remove } = useProjectByIdQuery(projectId);
  const { refetch } = useMyOrganisationsProjects();
  const { deleteProject } = useProject();

  const onConfirm = async () => {
    try {
      setShowPrompt(false);
      if (!project) {
        throw new Error("Project doesn't exist");
      }
      // Logically, navigation should happen after deleteProject, but it fails to run in that order.
      // Probably React doesn't like state updates after async or something. I haven't had a chance to look deeper.
      // But it doesn't cause the same problem in CreateProjectPanel so maybe some combination with what deleteProject does?
      // Most of the time the deletion succeeds and not navigating is more problematic so let's just navigate first.
      forceNavigate("/my-organisation");
      await deleteProject(project);
      setErrorModal({
        title: "Project deleted",
        content: `"${projectTitle}" project was deleted.`,
      });
      remove(); // Removes cache
      refetch(); // Refetch my organisation's projects
    } catch (error) {
      setErrorModal({
        title: "Request failed",
        content: "Failed to delete project.",
      });
    }
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-outline-danger mt-3"
        onClick={() => setShowPrompt(true)}
      >
        Delete project
      </button>
      <ConfirmCancelPromptModal
        show={showPrompt}
        title="Delete project?"
        content={`Are you sure you wish to delete the "${projectTitle}" project and all of its associated information?`}
        cancelText="Cancel"
        confirmText="Confirm deletion"
        onCancel={() => setShowPrompt(false)}
        onConfirm={onConfirm}
      />
    </>
  );
};
