import { SelectedProjectProvider } from "components/project/SelectedProjectProvider";
import { HoveredProjectProvider } from "components/project/hovered-project/HoveredProjectContext";
import { SearchedProjectsProvider } from "components/project/searched-projects/SearchedProjectsContext";
import { SelectedContextualFeatureProvider } from "components/layers/SelectedContextualFeatureProvider";
import { DrawLayerProvider } from "components/drawLayer/DrawLayerProvider";
import { ProjectProvider } from "components/project/ProjectProvider";
import { VectorGridProvider } from "components/map/VectorGridProvider";
import { LayersProvider } from "components/layers/LayersProvider";
import { PanelsProvider } from "components/panel/PanelsProvider";
import { DateRangeMapFilterProvider } from "./Map/map-controls/DateRangeMapFilterProvider";
import { SideBar } from "./Sidebar/Sidebar";
import { Panel } from "./Panel/Panel";
import { Map } from "./Map/Map";
import { MyOrganisationsProjectsMapFilterProvider } from "./Panel/MyOrganisation/MyOrganisationsProjectsMapFilterProvider";
import { WatchlistProvider } from "components/watchlist/WatchlistContext";
import { SavedSearchProvider } from "components/project/searched-projects/SavedSearchContext";
import { MyOrganisationsProjectsProvider } from "components/project/my-organisation/MyOrganisationsProjectsContext";
import { MessageOfTheDay } from "./MessageOfTheDay";

const motd = {
  enabled: true,
  motdKey: "watchlist-beta",
  title: "New features in the NFWV",
  content: (
    <>
      <p>
        We've added new features to the FWV:
        <ul className="mt-2">
          <li>project bookmarking</li>
          <li>watchlists</li>
          <li>notifications</li>
          <li>improved location search</li>
        </ul>
        These are in beta mode, so your feedback on the new functionality and
        location search would be greatly appreciated.
      </p>
      Please send feedback (and screenshots where possible) to{" "}
      <a href="mailto:support@forwardworks.co.nz">support@forwardworks.co.nz</a>
      .
    </>
  ),
};

export const MainPage = () => (
  <VectorGridProvider>
    <ProjectProvider>
      <SearchedProjectsProvider>
        <HoveredProjectProvider>
          <SelectedProjectProvider>
            <SavedSearchProvider>
              <WatchlistProvider>
                <DrawLayerProvider>
                  <LayersProvider>
                    <SelectedContextualFeatureProvider>
                      <DateRangeMapFilterProvider>
                        <MyOrganisationsProjectsMapFilterProvider>
                          <MyOrganisationsProjectsProvider>
                            <h1 className="visually-hidden">Main page</h1>
                            <div className="d-flex vh-100 overflow-hidden">
                              <PanelsProvider>
                                <SideBar />
                                <Panel />
                                <Map />
                              </PanelsProvider>
                            </div>
                            <MessageOfTheDay {...motd} />
                          </MyOrganisationsProjectsProvider>
                        </MyOrganisationsProjectsMapFilterProvider>
                      </DateRangeMapFilterProvider>
                    </SelectedContextualFeatureProvider>
                  </LayersProvider>
                </DrawLayerProvider>
              </WatchlistProvider>
            </SavedSearchProvider>
          </SelectedProjectProvider>
        </HoveredProjectProvider>
      </SearchedProjectsProvider>
    </ProjectProvider>
  </VectorGridProvider>
);
